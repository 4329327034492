.tags-list {
  a {
    margin-right: 0.5em;
    white-space: nowrap;
    opacity: 0.75;

    // No underline because it looks weird
    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }
  // Generate tag1 to tag25 classes
  @for $i from 1 through 18 {
    & span.tag-cloud-#{$i} {
      font-size: 0.1 * ($i - 1) + 1em;
    }
  }
}