ul.talks {
    list-style: none;
    line-height: 1.5;
    padding-left: 0;
    .detail {
        display: inline;
    }
    li {
        margin-bottom: 0.5rem;
    }
}